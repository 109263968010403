import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import SEO from "../seo";
import hero from "../images/hero3.jpg";
import pills from "../images/prep/pills-transparent.png";
import logo from "../images/logo.jpg";
import "../styles/index.css";

function Index() {
  return (
    <main>
      <SEO />

      <div className="relative bg-white overflow-hidden">
        <div className="max-w-3xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-24">
            <svg
              className="hidden lg:block absolute h-full w-24 text-white transform right-0 translate-x-full"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 100,0 0,100 0,100" />
            </svg>
            <div>
              <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                <nav
                  className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                  aria-label="Global"
                >
                  <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <a href="/">
                        <img className="h-20 mt-4" src={logo} alt="PREP.SHOP" />
                      </a>
                    </div>
                  </div>
                </nav>
              </div>
            </div>

            <main className="mt-10 mx-auto max-w-3xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div>
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className=" xl:inline">What is </span>
                  <span className=" text-blue-600 xl:inline">PrEP</span>
                  <span className=" xl:inline">?</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                  PrEP is a medicine you can take to protect you from getting
                  HIV from sex or injection drug use and it is extremely
                  effective when you take the pill correctly. So you don’t need
                  to worry about your own status or your sexual partner’s HIV
                  status.
                </p>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                  PrEP is a form of protection for people who do not have HIV
                  but who are at substantial risk of getting it.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <OutboundLink
                      href="https://buypreponline.com/?promo=1888"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                    >
                      Order PrEP today
                    </OutboundLink>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/3">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={hero}
            alt=""
          />
        </div>
      </div>

      <div className="relative py-12 bg-gray-50 overflow-hidden">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
            Questions and answers
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            What does the name PrEP mean?
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500">
            PrEP stands for <b>pre-exposure prophylaxis</b>. ‘pre’ is something
            you do before a risk of exposure (in this case to HIV),
            ‘prophylaxis’ is a treatment or action you can take to help prevent
            disease.
          </p>
          <p className="mt-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            What is HIV?
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500">
            HIV (human immunodeciency virus) is a virus that attacks the body’s
            immune system. If HIV is not treated, it can lead to AIDS (acquired
            immunodeciency syndrome). There is currently no effective cure. Once
            people get HIV, they have it for life. But with proper medical care,
            HIV can be controlled. People with HIV who get effective HIV
            treatment can live long, healthy lives and protect their partners.
          </p>
        </div>
      </div>

      <div className="py-12 bg-white">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
            Listing the facts
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Facts
          </p>
          <div>
            <div className="flex items-start mt-8">
              <div className="flex-shrink-0">
                <span className="bg-blue-500 h-10 w-10 pt-0.5 bg-teal-400 flex items-center justify-center rounded-full text-lg leading-10 font-display font-bold text-white">
                  01
                </span>
              </div>
              <div className="ml-4">
                <p className="text-lg leading-6 font-medium text-gray-900">
                  PrEP only protects you against HIV.
                </p>
                <p className="mt-1 max-w-xl text-base text-gray-500 lg:mt-2">
                  PrEP only protects you against HIV.
                </p>
              </div>
            </div>
            <div className="flex items-start mt-8">
              <div className="flex-shrink-0">
                <span className="bg-blue-500 h-10 w-10 pt-0.5 bg-teal-400 flex items-center justify-center rounded-full text-lg leading-10 font-display font-bold text-white">
                  02
                </span>
              </div>
              <div className="ml-4">
                <p className="text-lg leading-6 font-medium text-gray-900">
                  Other precautions are still necessary
                </p>
                <p className="mt-1 max-w-xl text-base text-gray-500 lg:mt-2">
                  To reduce your risk of contracting other sexually transmitted
                  infections (STIs) such as syphilis, chlamydia, gonorrhoea and
                  hepatitis C, you need to take other precautions. Ask your
                  doctor or sexual health clinic for more information.
                </p>
              </div>
            </div>
            <div className="flex items-start mt-8">
              <div className="flex-shrink-0">
                <span className="bg-blue-500 h-10 w-10 pt-0.5 bg-teal-400 flex items-center justify-center rounded-full text-lg leading-10 font-display font-bold text-white">
                  03
                </span>
              </div>
              <div className="ml-4">
                <p className="text-lg leading-6 font-medium text-gray-900">
                  Regular STI and HIV testing is recommended every three months.
                </p>
                <p className="mt-1 max-w-xl text-base text-gray-500 lg:mt-2">
                  Regular STI and HIV testing is recommended every three months.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-12 bg-gray-50">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div>
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
              All sorts of benefits
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              The benefits of PrEP
            </p>
            <p className="mt-4 text-xl text-gray-500 lg:mx-auto">
              Taking PrEP has all sorts of benefits. The following lists
              explains some of the benefits.
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Protection
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    You’re protecting yourself, so you don’t have to worry about
                    the status of your partner(s)
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    No condoms
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    You can have sex without condoms in a variety of situations.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Stay HIV negative
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    The perfect way to stay HIV negative.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Beneficial
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    If you’re struggling to maintain your HIV negative status
                    for whatever reason, it can offer huge benefits.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Empowerment and control
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    When you’re particularly a receptive partner, it offers you
                    empowerment and control.
                  </dd>
                </div>
              </div>

              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    Science-backed
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    PrEP is a well-studied, evidence-based method of avoiding
                    HIV transmission.
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div className="relative py-12 bg-blue-500">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div>
            <h2 className="text-base text-white font-semibold tracking-wide uppercase">
              Tricks of the trade
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
              How to take PrEP?
            </p>
            <p className="mt-4 max-w-2xl text-xl text-white">
              It depends on how far in advance you plan to have sex and how
              regularly and not necessarily how much sex you have. Take only 1
              pill every day. If you take more than 1 pill a day it could be
              harmful. Remember to take your pill at the same time every day and
              get into a routine, keep your pill bottle visible at a safe place.
            </p>
            <div className="mt-4 inline-flex rounded-md shadow">
              <OutboundLink
                href="https://www.sfaf.org/resource-library/tips-info-for-prep-users/"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md bg-gray-50 hover:bg-gray-200"
              >
                Learn more
              </OutboundLink>
            </div>
          </div>
          <div className="absolute lg:block hidden lg:bottom-0 transform -translate-x-full">
            <img className="" src={pills} alt="" />
          </div>
          <div className="lg:hidden -mb-12 -mt-12">
            <img
              src={pills}
              alt=""
              style={{ marginLeft: "auto", marginRight: 0 }}
            />
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="max-w-3xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Ready to dive in?</span>
            <span className="block text-blue-600">Order PrEP today.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <OutboundLink
                href="https://buypreponline.com/?promo=1888"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Order PrEP
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>

      <div className="py-12 bg-white">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/nZDVfi1TilA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <footer className="text-black sm:content-center border-t border-gray-300">
        <div className="bg-white">
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center lg:px-5 lg:py-6 mx-auto flex items-center sm:flex-row flex-col mb-2">
            <span className="text-sm text-gray-600 font-bold inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
              © PREP.SHOP 2021
            </span>
          </div>
        </div>
      </footer>
    </main>
  );
}

export default Index;
